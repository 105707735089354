import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Input from "../components/Input";
import styled from "styled-components";
import ComboButton from "../components/ComboButton";
import OptionText from "../components/OptionItem";
import { ReactComponent as Lock } from '../assets/lock.svg';
import HeaderCenter from "../components/HeaderCenter";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import Spinner from "../components/Spinner";
import HelperIcons from "../components/HelperIcons";

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DividerText = styled.p`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const DividerBefore = styled.div`
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
  margin: 5px;
`;

const DividerAfter = styled.div`
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
  margin: 5px;
`;

function PayMyBill() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  useEffect(() => {
    document.title = "Pay My Bill Page";
  }, []);
  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId));
    }
  }, [dispatch, storeId, facility]);

  if (!facility) {
    return<Spinner />;; // You can show a loading spinner here
  }

  return (
    <>
      <HeaderCenter />
      <Location
        bg={facility?.beautyShot}
        title={facility?.name}
        address={facility?.address}
        city={facility?.city}
        state={facility?.state}
        zip={facility?.zip_code}
        officeHours={facility?.officeHours}
        accessHours={facility?.accessHours}
      />
      <PgText title="Pay My Bill" text="Make a one time payment for your unit. Pay your bill by entering your phone number, email, or storage space number." />
      <Input radius="2px" labelText="Enter email" placeholder="Enter email" />
      <OptionText onClick={() => navigate('/billpay')} title="Search for unit" />
      <ComboButton width="200" topText='Hello' bottomText='Log into account' />
      <HelperIcons
      data-testid="help-icons"
      facility={facility} />
    </>
  );
}

export default PayMyBill;
