import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";
import AutoPayToggle from "./AutoPayToggle";

import { useDispatch, useSelector } from "react-redux";
import {
  faUser,
  faCreditCard,
  faCalendar,
  faKey,
  faMapMarkerAlt,
  faFileContract,
  faCheck,
  faCode,
  faCity,
  faMapPin,
  faFlag,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner"; // Assuming you have a Spinner component
import {
  createRental,
  resetCreateRentalStatus,
  clearSuccessMessage,
  createRentalStatus,
  successMessage,
  rentalError,
} from "../redux/rentalsSlice"; // Import selectors and actions

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  z-index: 1001; /* Ensure it is above other modal content */

  &:hover {
    color: #e22c2a;
  }
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

const SubHeader = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
`;
const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%; /* Aligns the line with the center of the circle */
    left: -30%; /* Positions the line to start from the middle of the previous circle */
    transform: translateY(-30%);
    height: 2px; /* Thickness of the line */
    width: 100%; /* Extends to the next step */
    background-color: #e22c2a; /* Line color */
    z-index: 0; /* Behind the step circle */
  }

  &:first-child::before {
    content: ""; /* No line before the first circle */
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Above the line */
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const CollapseButton = styled.button`
  background-color: #444;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    background-color: #333;
  }
`;

const DetailsSection = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  color: #e22c2a;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c92121;
  }
`;

const InsuranceWrapper = styled.div`
  margin-bottom: 20px;
`;

const InsuranceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
`;

const InsuranceList = styled.div`
  margin-bottom: 10px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const InsuranceListItem = styled.div`
  padding: 10px;
  border: 1px solid ${"#ddd"};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const InsuranceLabel = styled.label`
  margin-left: 10px;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#e22c2a" : "#333")};
`;

const InsurancePrice = styled.span`
  margin-left: auto;
  font-weight: bold;
  color: ${(props) => (props.selected ? "#e22c2a" : "#333")};
`;

const Radio = styled.input`
  cursor: pointer;
  accent-color: #e22c2a;
`;
const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: left;
`;

const NextStepText = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 20px;
  text-align: center;
`;
const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
`;
const ApiErrorText = styled.div`
  color: #e22c2a;
  font-size: 18px;
  margin: 10px;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9e9e9;
  text-align: center;
`;
function RentalModal({
  onRequestClose,
  onNext,
  facilityId,
  unit,
  reservation,
  discount,
  facility
}) {
  const dispatch = useDispatch();
  // const createRentalStatus = useSelector((state)=> state.rentals.createRentalStatus); // Track rental creation status
  // const rentSuccessMessage = useSelector(
  //   (state) => state.rentals.rentSuccessMessage
  // ); // Get success message from state
  const rentalError = useSelector((state) => state.rentals.rentalError); // Track rental error
  const tenant = useSelector((state) => state.auth.profile);
  const reservationsLoading = useSelector(
    (state) => state.reservations.resLoading
  ); // Select loading state from Redux

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [autopay, setAutopay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [name, setName] = useState(
    `${tenant?.first_name} ${tenant?.last_name}`
  );
  const [testCode, setTestCode] = useState(null);
  const [ccNumber, setCcNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [address1, setAddress1] = useState(tenant?.address || "");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState(tenant?.city || "");
  const [state, setState] = useState(tenant?.state || "");
  const [zipCode, setZipCode] = useState(tenant?.zip_code || "");
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [insuranceOptionsOpen, setInsuranceOptionsOpen] = useState(false);
  const [totalMoveInCosts, setTotalMoveInCosts] = useState(null);
  useEffect(() => {
    document.title = "Rental Modal";
  }, []); 
  
  useEffect(() => {
    if (tenant) {
      setName(`${tenant.first_name || ""} ${tenant.last_name || ""}`.trim());
      setAddress1(tenant.address || "");
      setCity(tenant.city || "");
      setState(tenant.state || "");
      setZipCode(tenant.zip_code || "");
    }
  }, [tenant]);
  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };

  // Ensure there is always an insurance option with id: -2 and omit id: -3
  const processInsuranceDetails = (insuranceDetails) => {
    const filteredDetails = insuranceDetails.filter(
      (insurance) => insurance.id !== -3
    );

    const hasOwnCoverageOption = filteredDetails.some(
      (insurance) => insurance.id === -2
    );

    if (!hasOwnCoverageOption) {
      // Add "I have my own coverage" option if not present
      filteredDetails.push({
        id: -2,
        coverage: 0,
        premium: 0,
        provider: null,
      });
    }

    return filteredDetails;
  };

  // Process insurance options
  const insuranceDetailsWithOwnCoverage = processInsuranceDetails(
    reservation?.insuranceDetails || []
  );

  const insuranceOptions = insuranceDetailsWithOwnCoverage.map((insurance) => ({
    id: insurance.id,
    label:
      insurance.id === -2
        ? "I have my own coverage"
        : insurance.provider
          ? `${insurance.provider} | Coverage: $${insurance.coverage}`
          : "No provider available",
    price:
      insurance.id === -2
        ? "" // No price for "I have my own coverage"
        : insurance.premium
          ? `$${insurance.premium}/mo`
          : "Included",
  }));

  const handleInsuranceSelect = (id) => {
    setSelectedInsurance(id);

    // Find the selected insurance from the insuranceOptions array
    const selectedOption = insuranceOptions.find((option) => option.id === id);

    // If the user selects "I have my own coverage" (id === -2), set premium to 0
    const premiumValue =
      id === -2
        ? 0
        : selectedOption?.price
          ? parseFloat(selectedOption.price.replace(/[$/mo]/g, ""))
          : 0;

    // Update the total move-in costs
    setTotalMoveInCosts((prevCosts) => {
      // Subtract the previous insurance premium and add the new one
      const baseMoveInCosts =
        prevCosts -
        (selectedInsurance !== null
          ? insuranceOptions
              .find((option) => option.id === selectedInsurance)
              ?.price.replace(/[$/mo]/g, "") || 0
          : 0);
      return parseFloat(baseMoveInCosts) + premiumValue;
    });
  };

  const toggleInsuranceOptions = () => {
    setInsuranceOptionsOpen(true);
  };
  // Function to handle clicks outside the modal
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  const validateForm = () => {
    let errors = {};

    if (!name.trim()) errors.name = "Name on card is required.";
    if (!ccNumber.trim())
      errors.ccNumber = "Credit or debit card number is required.";
    else if (!/^\d{15,16}$/.test(ccNumber))
      errors.ccNumber = "Card number must be 16 digits.";

    if (!expiry.trim()) errors.expiry = "Card expiry date is required.";
    else if (!/^\d{2}\/\d{2}$/.test(expiry))
      errors.expiry = "Expiry must be in MM/YY format.";

    if (!cvv.trim()) errors.cvv = "CVC code is required.";
    else if (!/^\d{3,4}$/.test(cvv)) errors.cvv = "CVC must be 3 or 4 digits.";

    if (!address1.trim()) errors.address1 = "Billing address is required.";
    if (!city.trim()) errors.city = "City is required.";
    if (!state.trim()) errors.state = "State is required.";
    if (!zipCode.trim()) errors.zipCode = "ZIP code is required.";
    else if (!/^\d{5}(-\d{4})?$/.test(zipCode))
      errors.zipCode = "Invalid ZIP code format.";

    if (selectedInsurance === null)
      errors.selectedInsurance = "Please select a property protection plan.";

    setError(errors);
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };
  const handleRentalCreation = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails

    if (!totalMoveInCosts) {
      setError({
        form: "Move-in costs are not calculated yet. Please try again.",
      });
      return;
    }

    setLoading(true);
    setApiError("Move-in costs are not calculated yet. Please try again");

    try {
      const secretKey = process.env.REACT_APP_CC_SEC;
      if (!secretKey) throw new Error("Encryption key is missing.");

      const encryptedCcNumber = CryptoJS.AES.encrypt(
        ccNumber,
        secretKey
      ).toString();
      const [expMonth, expYearPartial] = expiry.split("/");
      const expYear =
        expYearPartial.length === 2 ? `20${expYearPartial}` : expYearPartial;
      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-CA");

      const rentalData = {
        unit_id: reservation?.unit_id,
        payment_amount: totalMoveInCosts,
        card_type: getCardType(ccNumber),
        card_number: encryptedCcNumber,
        card_name: name,
        card_exp_month: expMonth,
        card_exp_year: expYear,
        card_address: address1,
        card_zip: zipCode,
        card_cvv: cvv,
        autopay,
        reservation_id: reservation?.id,
        insurance_id: selectedInsurance,
        start_date: formattedDate,
        testCode: testCode,
      };

      const response = await dispatch(
        createRental({ facilityId, tenantId: tenant?.i, rentalData })
      ).unwrap();

      onNext(2);
    } catch (err) {
      setApiError("Failed to create rental."); // Display the error in the modal
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      // dispatch(resetCreateRentalStatus());
      setApiError(null);
    };
  }, [dispatch]);

  // Clear success message after displaying it
  useEffect(() => {
    if (createRentalStatus === "success") {
      setTimeout(() => {
        dispatch(clearSuccessMessage());
        // dispatch(resetCreateRentalStatus());
      }, 3000); // Clears after 3 seconds
    }
  }, [createRentalStatus, dispatch]);
  useEffect(() => {
    if (reservation?.unitDetails?.discounts) {
      const discountDetails = reservation?.unitDetails.discounts[0];
      if (discountDetails && discountDetails.moveInCosts) {
        const { charges, total } = discountDetails.moveInCosts;
        const discountCharge = charges.find(
          (charge) => charge.name === "Discount"
        );
        const adminCharge = charges.find(
          (charge) => charge.name === "Administrative Fee"
        );

        const discountAmount = discountCharge?.amount || 0;
        const adminFee = adminCharge?.amount || 0;
        setTotalMoveInCosts(parseFloat(total.toFixed(2)));
      }
    }
  }, [reservation]);

  const fillWithDemoData = () => {
    setCcNumber("374101000009876");
    setExpiry("12/25");
    setCvv("123");
    setAddress1("123 Demo Street");
    setAddress2("Apt 101");
    setCity("Demo City");
    setState("CA");
    setZipCode("90210");
  };
  function getCardType(number) {
    const cardPatterns = {
      1: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
      2: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/, // MasterCard
      3: /^3[47][0-9]{13}$/, // American Express
      4: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
    };
    return (
      Object.entries(cardPatterns).find(([_, pattern]) =>
        pattern.test(number)
      )?.[0] || null
    );
  }
  function convertPhone(numStr) {
    // Ensure it's a string
    numStr = numStr.toString();
  
    // Remove leading '1' if it exists
    if (numStr.startsWith("1")) {
      numStr = numStr.slice(1);
    }
  
    // Format for display (e.g., (XXX) XXX-XXXX)
    const formattedDisplay = `(${numStr.slice(0, 3)}) ${numStr.slice(3, 6)}-${numStr.slice(6)}`;
  
    // Format for href (e.g., 18448884848)
    const formattedHref = `tel:1${numStr}`;
  
    return { formattedDisplay, formattedHref };
  }

  // Find the move-in cost object with the matching name
  const discountDetails = reservation?.unitDetails.discounts[0];

  // Extract the discount amount
  let discountAmount = null;
  let adminFee = null;
  if (
    discountDetails &&
    discountDetails.moveInCosts &&
    discountDetails.moveInCosts.charges
  ) {
    const discountCharge = discountDetails.moveInCosts.charges.find(
      (charge) => charge.name === "Discount"
    );
    const adminCharge = discountDetails.moveInCosts.charges.find(
      (charge) => charge.name === "Administrative Fee"
    );
    discountAmount = discountCharge ? discountCharge.amount : null;
    adminFee = adminCharge ? adminCharge.amount : null;
  }
  
  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>We Have Received Your Reservation!</Header>
        {loading || reservationsLoading || !tenant || !tenant?.first_name ? (
          <Spinner />
        ) : (
          <>
            <SubHeader>
              To complete your rental online, submit your payment below. You can
              also call us or walk into our store to finish your rental.
            </SubHeader>

            <StepsContainer>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faCreditCard} />
                </StepCircle>
                {(tenant?.email === "testerjoe80@gmail.com" ||
                  tenant?.email === "james@testerson.com") && (
                  <StepLabel onClick={fillWithDemoData}>
                    Enter Payment
                  </StepLabel>
                )}
                {tenant?.email !== "testerjoe80@gmail.com" &&
                  tenant?.email !== "james@testerson.com" && (
                    <StepLabel>Enter Payment</StepLabel>
                  )}
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faUser} />
                </StepCircle>
                <StepLabel>Alt Contact</StepLabel>
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faClipboard} />
                </StepCircle>
                <StepLabel>More Info</StepLabel>
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faFileContract} />
                </StepCircle>
                <StepLabel>Sign Lease</StepLabel>
              </Step>
            </StepsContainer>

            <CollapseButton onClick={toggleDetails}>
              Details {detailsOpen ? "▲" : "▼"}
            </CollapseButton>
            <DetailsSection isOpen={detailsOpen}>
              <div>
                <strong style={{ fontSize: 14 }}>UNIT SIZE</strong>
                <p style={{ fontSize: 10 }}>5x5 | Small Storage</p>
              </div>
              <div>
                <strong style={{ fontSize: 14 }}>COST DETAILS</strong>
                <p style={{ fontSize: 10 }}>
                  First Month's Rent:{" "}
                  <b>
                    $
                    {reservation?.unitDetails?.street_rate ||
                      reservation?.unitDetails?.price?.street_rate?.toFixed(
                        2
                      ) ||
                      "-"}
                  </b>
                </p>
                {discountAmount && (
                  <p style={{ fontSize: 10 }}>
                    Discount: <b>${discountAmount?.toFixed(2) || "-"}</b>
                  </p>
                )}
                <p style={{ fontSize: 10 }}>
                  Admin Fee: <b>${adminFee?.toFixed(2) || "-"}</b>
                </p>
                <p style={{ fontSize: 10 }}>
                  First Month’s Property Protection Plan:{" "}
                  {selectedInsurance === -2
                    ? "$0"
                    : selectedInsurance
                      ? `$${
                          insuranceOptions
                            .find((option) => option.id === selectedInsurance)
                            ?.price.replace(/[$/mo]/g, "") || "0"
                        }`
                      : "Choose coverage below"}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Total Move-In Cost: $
                  {(totalMoveInCosts || 0).toFixed(2) || "-"}
                </p>
              </div>
            </DetailsSection>

            <FormSection>
              {apiError && (
                <ApiErrorText>
                  {apiError}
                  <p>
                    If you need assistance, please call us at{" "}
                    <a
                      href={`tel:${facility?.customer_on_property_number || '8444341150'}`}
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      {
                            convertPhone(
                              facility?.customer_on_property_number ||
                                "8444341150"
                            ).formattedDisplay
                          }
                    </a>
                  </p>
                </ApiErrorText>
              )}
              <PaymentHeader>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  size="lg"
                  style={{ marginRight: "10px" }}
                />
                Please Provide Your Payment Details
              </PaymentHeader>
              {(tenant?.email === "testerjoe80@gmail.com" ||
                tenant?.email === "james@testerson.com") && (
                <>
                  <Label>Test Code</Label>
                  <InputWrapper>
                    <Icon icon={faCode} />
                    <Input
                      type="text"
                      value={testCode}
                      onChange={(e) => setTestCode(e.target.value)}
                      placeholder="404,500"
                    />
                  </InputWrapper>
                </>
              )}

              <Label>Name on Card *</Label>
              <InputWrapper>
                <Icon icon={faUser} />
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name on Card"
                />
              </InputWrapper>
              {error?.name && <ErrorText>{error.name}</ErrorText>}

              <Label>Credit or Debit Card # *</Label>
              <InputWrapper>
                <Icon icon={faCreditCard} />
                <Input
                  type="text"
                  value={ccNumber}
                  onChange={(e) => setCcNumber(e.target.value)}
                  placeholder="Credit or Debit Card #"
                />
              </InputWrapper>
              {error?.ccNumber && <ErrorText>{error.ccNumber}</ErrorText>}

              <Label>MM/YY *</Label>
              <InputWrapper>
                <Icon icon={faCalendar} />
                <Input
                  type="text"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  placeholder="MM/YY"
                />
              </InputWrapper>
              {error?.expiry && <ErrorText>{error.expiry}</ErrorText>}

              <Label>CVC Code *</Label>
              <InputWrapper>
                <Icon icon={faKey} />
                <Input
                  type="text"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  placeholder="CVC Code"
                />
              </InputWrapper>
              {error?.cvv && <ErrorText>{error.cvv}</ErrorText>}

              <Label>Billing Address 1 *</Label>
              <InputWrapper>
                <Icon icon={faMapMarkerAlt} />
                <Input
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  placeholder="Billing Address 1"
                />
              </InputWrapper>
              {error?.address1 && <ErrorText>{error.address1}</ErrorText>}

              <Label>Billing Address 2</Label>
              <InputWrapper>
                <Icon icon={faMapMarkerAlt} />
                <Input
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  placeholder="Billing Address 2"
                />
              </InputWrapper>

              <Label>City *</Label>
              <InputWrapper>
                <Icon icon={faCity} />
                <Input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="City"
                />
              </InputWrapper>
              {error?.city && <ErrorText>{error.city}</ErrorText>}

              <Label>State *</Label>
              <InputWrapper>
                <Icon icon={faFlag} />
                <Input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  placeholder="State"
                />
              </InputWrapper>
              {error?.state && <ErrorText>{error.state}</ErrorText>}

              <Label>ZIP *</Label>
              <InputWrapper>
                <Icon icon={faMapPin} />
                <Input
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  placeholder="ZIP"
                />
              </InputWrapper>
              {error?.zipCode && <ErrorText>{error.zipCode}</ErrorText>}

              <InsuranceWrapper>
                <InsuranceHeader onClick={toggleInsuranceOptions}>
                  <b>Select your property protection plan*</b>{" "}
                  <span style={{ color: "#e22c2a" }}>
                    {insuranceOptionsOpen ? "▲" : "▼"}
                  </span>
                </InsuranceHeader>
                <InsuranceList isOpen={insuranceOptionsOpen}>
                  {insuranceOptions.map((option) => (
                    <InsuranceListItem
                      key={option.id}
                      selected={selectedInsurance === option.id}
                    >
                      <Radio
                        type="radio"
                        name="insurance"
                        value={option.id}
                        checked={selectedInsurance === option.id}
                        onClick={() => handleInsuranceSelect(option.id)}
                      />
                      <InsuranceLabel
                        selected={selectedInsurance === option.id}
                      >
                        {option.label}
                      </InsuranceLabel>
                      {option.price && (
                        <InsurancePrice
                          selected={selectedInsurance === option.id}
                        >
                          {option.price}
                        </InsurancePrice>
                      )}
                    </InsuranceListItem>
                  ))}
                </InsuranceList>
                {error?.selectedInsurance && (
                  <ErrorText>{error.selectedInsurance}</ErrorText>
                )}
              </InsuranceWrapper>

              {insuranceOptionsOpen && (
                <Note>
                  Select your coverage* amount based on the approximate value of
                  your stored goods.
                </Note>
              )}
              {insuranceOptionsOpen && (
                <Note>
                  *If you have your own coverage, please provide your property
                  Manager a current copy of your Renters Insurance within 72
                  hours. If documentation is not provided, then the minimum
                  insurance will be added to your account.
                </Note>
              )}
              <AutoPayToggle checked={autopay} onChange={setAutopay} />

              <ConfirmButton onClick={handleRentalCreation}>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{ marginRight: "5px" }}
                />
                Confirm Payment
              </ConfirmButton>

              <NextStepText>Next step: Additional Contact.</NextStepText>
            </FormSection>
          </>
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}

export default RentalModal;
