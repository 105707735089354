import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Unit from "../components/Unit";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import Spinner from "../components/Spinner";
import NickHeaderCenter from "../components/NickHeaderCenter";
import HelperIcons from "../components/HelperIcons";
const ManageUnits = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const { isAuthenticated, email, first,  s } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId));
    }
  }, [dispatch, storeId, facility]);

  if (!facility) {
    return<Spinner />;; // You can show a loading spinner here
  }

  return (
    <>
       <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
        one={true}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "100px"
        }}
      >

      <PgText title="Manage Storage Spaces" text="Manage your storage spaces at this location." />
      <Unit unitName='Small' unitSize='5x4' unitPrice='48' discount='58' where='In-Store' featureText='Drive Up' from="manage" hideRentNow={true} />
      </div>
      <HelperIcons
      data-testid="help-icons"
      facility={facility} />
    </>
  );
};

export default ManageUnits;
