import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addAlternateContact } from "../redux/rentalsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faCity,
  faMapPin,
  faFlag,
  faCheck,
  faCreditCard,
  faFileContract,
  faClipboard,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner";

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -30%;
    transform: translateY(-30%);
    height: 2px;
    width: 100%;
    background-color: #e22c2a;
    z-index: 0;
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;

  &:hover {
    background-color: #c92121;
  }
`;

const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
`;

function AlternateContactModal({
  facilityId,
  tenantId,
  onRequestClose,
  onNext,
  reservation,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [testCode, setTestCode] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Alternate Contact Modal";
  }, []);
  
  const { successMessage, error } = useSelector((state) => state.profile);
  const tenant = useSelector((state) => state.auth.profile);
  const activeModalSession = useSelector((state)=> state.modal.activeModalSession)
  const rentalsLoading = useSelector((state)=>state.rentals.rentLoading)
  const selectedRentalId =
    useSelector((state) => state.rentals.selectedRentalId) ||
    reservation.rentalInfo.id; // Access the selected rental ID
  const validateForm = () => {
    let validationErrors = {};
    if (!firstName.trim())
      validationErrors.firstName = "First name is required.";
    if (!lastName.trim()) validationErrors.lastName = "Last name is required.";
    if (!address1.trim()) validationErrors.address1 = "Address is required.";
    if (!city.trim()) validationErrors.city = "City is required.";
    if (!state.trim()) validationErrors.state = "State is required.";
    if (!zip.trim()) validationErrors.zip = "ZIP code is required.";
    if (!phone.trim()) validationErrors.phone = "Phone number is required.";
    if (!email.trim()) validationErrors.email = "Email is required.";
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    if (cleaned.length < 4) return cleaned;
    if (cleaned.length < 7) return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };
  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedNumber);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    const alternateContactData = {
      first_name: firstName,
      last_name: lastName,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      email,
      testCode: testCode,
    };

    try {
      // Dispatch addAlternateContact using facilityId, tenantId, and selectedRentalId
      await dispatch(
        addAlternateContact({
          facilityId,
          tenantId:tenant?.i,
          rentalId: selectedRentalId, // Use the selected rental ID
          alternateContactData,
        })
      ).unwrap();

      onNext(activeModalSession?3:reservation?.rentalInfo?.contacts?.[0] &&
        reservation?.rentalInfo?.leaseSignUrl?4: reservation?.rentalInfo?.leaseSignUrl ? 4:5); // Move to the next step if successful
    } catch (err) {
      console.error("Failed to add alternate contact:", err);
    } finally {
      setLoading(false);
    }
  };
  const fillWithDemoData = () => {
    setFirstName("Jane");
    setLastName("Doe");
    setAddress1("123 Demo Lane");
    setAddress2("Suite 202");
    setCity("Demo City");
    setState("CA");
    setZip("90001");
    setPhone("555-123-4567");
    setEmail("janedoe@example.com");
  };

  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };

  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>Alternate Contact</Header>
        {loading || rentalsLoading? (
          <Spinner />
        ) : (
          <>

            <StepsContainer>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faCreditCard} />
                </StepCircle>
                <StepLabel>Enter Payment</StepLabel>
              </Step>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faUser} />
                </StepCircle>
                {(tenant?.email === "testerjoe80@gmail.com" ||
                  tenant?.email === "james@testerson.com") && (
                  <StepLabel onClick={fillWithDemoData}>Alt Contact</StepLabel>
                )}
                {tenant?.email !== "testerjoe80@gmail.com" &&
                  tenant?.email !== "james@testerson.com" && (
                    <StepLabel>Alt Contact</StepLabel>
                  )}
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faClipboard} />
                </StepCircle>
                <StepLabel>More Info</StepLabel>
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faFileContract} />
                </StepCircle>
                <StepLabel>Sign Lease</StepLabel>
              </Step>
            </StepsContainer>

            <FormSection>
              {(tenant?.email === "testerjoe80@gmail.com" ||
                tenant?.email === "james@testerson.com") && (
                <>
                  <Label>Test Code</Label>
                  <InputWrapper>
                    <Icon icon={faCode} />
                    <Input
                      type="text"
                      value={testCode}
                      onChange={(e) => setTestCode(e.target.value)}
                      placeholder="404,500"
                    />
                  </InputWrapper>
                </>
              )}
              <Label>First Name *</Label>
              <InputWrapper>
                <Icon icon={faUser} />
                <Input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </InputWrapper>
              {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}

              <Label>Last Name *</Label>
              <InputWrapper>
                <Icon icon={faUser} />
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </InputWrapper>
              {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}

              <Label>Address 1 *</Label>
              <InputWrapper>
                <Icon icon={faMapMarkerAlt} />
                <Input
                  type="text"
                  placeholder="Address 1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </InputWrapper>
              {errors.address1 && <ErrorText>{errors.address1}</ErrorText>}

              <Label>Address 2</Label>
              <InputWrapper>
                <Icon icon={faMapMarkerAlt} />
                <Input
                  type="text"
                  placeholder="Address 2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </InputWrapper>

              <Label>City *</Label>
              <InputWrapper>
                <Icon icon={faCity} />
                <Input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </InputWrapper>
              {errors.city && <ErrorText>{errors.city}</ErrorText>}

              <Label>State *</Label>
              <InputWrapper>
                <Icon icon={faFlag} />
                <Input
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </InputWrapper>
              {errors.state && <ErrorText>{errors.state}</ErrorText>}

              <Label>ZIP *</Label>
              <InputWrapper>
                <Icon icon={faMapPin} />
                <Input
                  type="text"
                  placeholder="ZIP"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </InputWrapper>
              {errors.zip && <ErrorText>{errors.zip}</ErrorText>}

              <Label>Phone Number</Label>
                <InputWrapper>
                  <Icon icon={faPhone} />
                  <Input
                    type="text"
                    value={phone}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number"
                    maxLength="12"
                  />
                </InputWrapper>
              {errors.phone && <ErrorText>{errors.phone}</ErrorText>}

              <Label>Email Address *</Label>
              <InputWrapper>
                <Icon icon={faEnvelope} />
                <Input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputWrapper>
              {errors.email && <ErrorText>{errors.email}</ErrorText>}
            </FormSection>

            <ConfirmButton onClick={handleSubmit}>Add Contact</ConfirmButton>
            {successMessage && (
              <Note style={{ color: "green" }}>{successMessage}</Note>
            )}
            {error && <ErrorText>{error}</ErrorText>}
            <Note>You're doing great!</Note>
          </>
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}

export default AlternateContactModal;
