import axios from "axios";
import { store } from "../redux/store";
import { logoutUser } from "../redux/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let cachedClientId = null;

const getGaSessionId = () => {
  const gaCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_ga='));

  if (gaCookie) {
    const gaValue = gaCookie.split('=')[1]; // Get the cookie value
    const parts = gaValue.split('.'); // Split by dots
    if (parts.length === 4) {
      return parts[2] + '.' + parts[3]; // Session ID
    }
  }
  console.warn('GA session ID not found');
  return null;
};
const getGaIdentifiers = () =>
  new Promise(async (resolve, reject) => {
    try {
      const gaCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('_ga='));

      if (!gaCookie) {
        console.warn('GA cookie not found');
        reject('GA cookie not found');
        return;
      }

      const gaValue = gaCookie.split('=')[1]; // Get the cookie value
      const parts = gaValue.split('.'); // Split by dots

      if (parts.length === 4) {
        const clientId = `${parts[2]}.${parts[3]}`; // Combine 3rd and 4th segments for Client ID
        const sessionId = parts[3]; // Use only the 4th segment for Session ID

        resolve({ clientId, sessionId });
      } else {
        console.warn('Invalid GA cookie format');
        reject('Invalid GA cookie format');
      }
    } catch (error) {
      console.error('Error retrieving GA identifiers:', error);
      reject(error);
    }
  });

// Create an Axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    try {
      const { clientId, sessionId } = await getGaIdentifiers();
      if (clientId) {
        config.headers['X-GA4-Client-ID'] = clientId;
      }
      if (sessionId) {
        config.headers['X-GA4-Session-ID'] = sessionId;
      }
    } catch (error) {
      console.warn('Unable to retrieve GA identifiers:', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.error("Network error:", error.message);
      return Promise.reject(error);
    }

    const { status } = error.response;
    if (status === 401 || status === 403) {
      store.dispatch(logoutUser());

      // Show a toast notification to inform the user
      toast.warn("Session expired. Please log in again.", {
        position: "top-center",
        autoClose: 5000,
      });

      setTimeout(() => {
        window.location.href = "/"; // Redirect to login page after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return Promise.reject(error);
  }
);

export default instance;
