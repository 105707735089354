import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UnitsContainer,
  UnitWrapper,
  UnitTopInfoWrapper,
  UnitContainer,
  UnitImage,
  UnitName,
  UnitSize,
  UnitPrice,
  UnitStrike,
  DiscountBadge,
  BadgeIcon,
  UnitDivider,
  PriceBreakdownContainer,
  BreakdownRow,
  BreakdownTitle,
  BreakdownTotal,
  IconWrap,
  Divider,
  Note,
  NoteI,
  NoteH,
  MathDivider,
  ToggleButton,
  UnitFeatureTitle,
} from "./Unit.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { openModal } from "../redux/modalSlice";
import { unitImageMap, getFeatureIconAndTitle, validFeatures } from "./utils";

function UnitRes({
  reservationId,
  unitName,
  unitSize,
  street_rate,
  web_rate,
  area,
  unit,
  hideButtons,
}) {
  const dispatch = useDispatch();
  const [isPriceBreakdownOpen, setPriceBreakdownOpen] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [moveInCosts, setMoveInCosts] = useState({ charges: [], total: 0 });

  useEffect(() => {
    // Fetch discount and move-in costs
    const foundDiscount = unit?.discounts?.[0];
    if (foundDiscount) {
      setDiscount(foundDiscount);
      setMoveInCosts(foundDiscount.moveInCosts || { charges: [], total: 0 });
    }
  }, [unit]);

  const handleRentNowClick = () => {
    dispatch(
      openModal({
        type: "reservation",
        unit,
        reservationId,
      })
    );
  };

  const togglePriceBreakdown = () => {
    setPriceBreakdownOpen((prev) => !prev);
  };

  return (
    <UnitsContainer>
      <UnitWrapper>
        <UnitTopInfoWrapper>
          <UnitImage src={unitImageMap[unitSize]?.src || unitImageMap["default"].src} alt={unitSize} />

          <UnitContainer>
            <UnitName>{area < 100 ? "Small" : area < 200 ? "Medium" : "Large"}</UnitName>
            <UnitSize>{unitSize}</UnitSize>
          </UnitContainer>

          <UnitContainer>
            <UnitPrice>${street_rate}/mo</UnitPrice>
            <UnitStrike>${web_rate}</UnitStrike>
          </UnitContainer>
        </UnitTopInfoWrapper>

        {discount?.id && (
          <DiscountBadge>
            <BadgeIcon>
              <FontAwesomeIcon icon={faTag} />
            </BadgeIcon>
            {discount.name}
          </DiscountBadge>
        )}

        <UnitDivider />

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {unit?.features
            ?.filter((feature) => validFeatures.has(feature.toLowerCase()))
            .map((feature, index, arr) => {
              const { featureIcon, featureTitle } = getFeatureIconAndTitle(feature);
              return (
                <React.Fragment key={index}>
                  <IconWrap>
                    {featureIcon}
                    <UnitFeatureTitle>{featureTitle}</UnitFeatureTitle>
                  </IconWrap>
                  {index < arr.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
        </div>

        {isPriceBreakdownOpen && (
          <PriceBreakdownContainer>
            {moveInCosts.charges.map((charge, index) => (
              <BreakdownRow key={index}>
                <span>{charge.name}</span>
                <span>${(charge.amount + charge.tax).toFixed(2)}</span>
              </BreakdownRow>
            ))}
            <hr />
            <BreakdownRow>
              <BreakdownTitle>Total Move-In Cost</BreakdownTitle>
              <BreakdownTotal>${moveInCosts.total?.toFixed(2)}</BreakdownTotal>
            </BreakdownRow>
            <Note>*Prices may not include all taxes. Applicable taxes will be added to the total amount.</Note>
          </PriceBreakdownContainer>
        )}

        {!hideButtons ? (
          <ToggleButton onClick={handleRentNowClick}>Reserve Now</ToggleButton>
        ) : (
          <ToggleButton onClick={togglePriceBreakdown}>
            Price Breakdown {isPriceBreakdownOpen ? "▲" : "▼"}
          </ToggleButton>
        )}
      </UnitWrapper>
    </UnitsContainer>
  );
}

export default UnitRes;
