import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import BillPay from './Pages/BillPayPage';
import AccessCodes from './Pages/AccessCodesPage';
import Account from './Pages/Account';
import UserProfile from './Pages/UserProfile';
import PayMyBill from './Pages/PayMyBill';
import Rental from './Pages/Rental';
import NewRental from './Pages/NewRental';
import MyReservation from './Pages/MyReservation';
import ManageUnits from './Pages/ManageUnits';
import ModalManager from './components/ModalManager';
import PersistQueryParams from './components/PersistQueryParams';
import './App.css';

function App() {
  const routes = [
    { path: '/', element: <Home /> },
    { path: '/login', element: <Login /> },
    { path: '/billpay', element: <BillPay /> },
    { path: '/accesscodes', element: <AccessCodes /> },
    { path: '/account', element: <Account /> },
    { path: '/userprofile', element: <UserProfile /> },
    { path: '/paymybill', element: <PayMyBill /> },
    { path: '/rental', element: <Rental /> },
    { path: '/newrental', element: <NewRental /> },
    { path: '/myreservations', element: <MyReservation /> },
    { path: '/manageunits', element: <ManageUnits /> },
  ];

  return (
    <Router>
      <PersistQueryParams routes={routes} />
      <ModalManager />
    </Router>
  );
}

export default App;
