import React, {useEffect} from "react";
import BottomButton from "../components/BottomButton";
import {ReactComponent as NSALogo2} from '../assets/nsaLogo2.svg';
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AddressForm from '../components/AddressForm';
import NickHeaderCenter from "../components/NickHeaderCenter";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore, selectedTenantId } from "../redux/authSlice";
import HelperIcons from "../components/HelperIcons";




function UserProfile () {
    let navigate = useNavigate();
    const storeId = useSelector(selectedStore);
    const tenantId = useSelector(selectedTenantId);
    const facility = useSelector((state) => selectFacilityById(state, storeId));
    useEffect(() => {
      document.title = "User Profile Page";
    }, []);
    const { reservations, loading, error } = useSelector(
      (state) => state.reservations
    );
    const { isAuthenticated, email, first,  s } = useSelector(
      (state) => state.auth
    );
    return (
        <>
        <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
        one={true}
      />
        <AddressForm
        facility={facility}
        />
       
       <HelperIcons
      data-testid="help-icons"
      facility={facility} />
        </>
    );

}

export default UserProfile;