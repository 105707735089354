import styled from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMapMarkerAlt, faBuilding, faCity, faFlag, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const MessageContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #555;
`;

const ContactButton = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #e22c2a;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #b2221f;
  }
`;

function AddressForm(facility) {
  const profile = useSelector((state) => state.auth.profile);
  const errors = {}; // Replace with actual error state as needed

  return (
    <FormContainer>
      <Label>Full Name</Label>
      <InputWrapper>
        <Icon icon={faUser} />
        <Input
          type="text"
          placeholder="Enter your full name"
          value={`${profile.first_name.trim()} ${profile.last_name.trim()}`}
          readOnly
          disabled
        />
      </InputWrapper>
      {errors.full_name && <ErrorText>{errors.full_name}</ErrorText>}

      <Label>Address Line 1</Label>
      <InputWrapper>
        <Icon icon={faMapMarkerAlt} />
        <Input
          type="text"
          placeholder="Enter your address"
          value={profile.address || ""}
          readOnly
          disabled
        />
      </InputWrapper>
      {errors.address && <ErrorText>{errors.address}</ErrorText>}

      <Label>Address Line 2</Label>
      <InputWrapper>
        <Icon icon={faBuilding} />
        <Input
          type="text"
          placeholder="Apartment, suite, etc. (optional)"
          value={profile.address_line_2 || ""}
          disabled
        />
      </InputWrapper>
      {errors.address_line_2 && <ErrorText>{errors.address_line_2}</ErrorText>}

      <Label>City</Label>
      <InputWrapper>
        <Icon icon={faCity} />
        <Input
          type="text"
          placeholder="Enter your city"
          value={profile.city || ""}
          readOnly
          disabled
        />
      </InputWrapper>
      {errors.city && <ErrorText>{errors.city}</ErrorText>}

      <Label>State/Province/Region</Label>
      <InputWrapper>
        <Icon icon={faFlag} />
        <Input
          type="text"
          placeholder="Enter your state/province/region"
          value={profile.state || ""}
          readOnly
          disabled
        />
      </InputWrapper>
      {errors.state && <ErrorText>{errors.state}</ErrorText>}

      <Label>ZIP/Postal Code</Label>
      <InputWrapper>
        <Icon icon={faEnvelope} />
        <Input
          type="text"
          placeholder="Enter your ZIP/postal code"
          value={profile.zip_code || ""}
          disabled
        />
      </InputWrapper>
      {errors.zip_code && <ErrorText>{errors.zip_code}</ErrorText>}

      <MessageContainer>
        <p>To update your personal information:</p>
        <ContactButton href={`tel:${facility?.customer_on_property_number || '8444341150'}`}>Call Customer Service</ContactButton>
      </MessageContainer>
    </FormContainer>
  );
}

export default AddressForm;
