import React from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectedStore } from "../redux/authSlice";
import { selectFacilityById } from "../redux/facilitiesSlice";

const PersistQueryParams = ({ routes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoutes = useRoutes(routes);
  const storeId = useSelector(selectedStore); // Get the selected store ID from Redux
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  // Function to navigate while appending ?s={storeId}
  const navigateWithQuery = (path) => {
    const searchParams = new URLSearchParams(location.search);

    // Append or update the `s` query parameter with `storeId`
    if (storeId) {
      searchParams.set("s", storeId);
    }

    // Navigate with the updated query string
    navigate(`${path}?${searchParams.toString()}`);
  };

  // Update current routes to include ?s={storeId} when rendered
  React.useEffect(() => {
    if (storeId && facility?.nsa_id) {
      const searchParams = new URLSearchParams(location.search);
  
      // Check if the current query parameters already have the correct values
      const hasCorrectStoreId = searchParams.get('s') === storeId;
      const hasCorrectNsaId = searchParams.get('n') === facility.nsa_id;
  
      if (!hasCorrectStoreId || !hasCorrectNsaId) {
        // Only update the URL if the parameters are missing or incorrect
        if (!hasCorrectStoreId) {
          searchParams.set('s', storeId);
        }
        if (!hasCorrectNsaId) {
          searchParams.set('n', facility.nsa_id);
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      }
    }
  }, [storeId, facility?.nsa_id, location.pathname, location.search, navigate]);
  return currentRoutes;
};

export default PersistQueryParams;
