import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UnitsContainer,
  UnitWrapper,
  UnitTopInfoWrapper,
  UnitContainer,
  UnitImage,
  UnitName,
  UnitSize,
  UnitPrice,
  UnitStrike,
  DiscountBadge,
  BadgeIcon,
  UnitDivider,
  PriceBreakdownContainer,
  BreakdownRow,
  BreakdownTitle,
  BreakdownTotal,
  IconWrap,
  Divider,
  Note,
  ToggleButton,
  ActionButton,
  UnitFeatureTitle,
} from "./Unit.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { openModal } from "../redux/modalSlice";
import { unitImageMap, getFeatureIconAndTitle, validFeatures } from "./utils";
import { cancelRental } from "../redux/reservationSlice";

function Unit({
  reservation,
  unitName,
  unitSize,
  street_rate,
  web_rate,
  unit,
  facility,
  rentalStatus,
  hideButtons,
  leaseSignUrl,
}) {
  const dispatch = useDispatch();
  const [isPriceBreakdownOpen, setPriceBreakdownOpen] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [moveInCosts, setMoveInCosts] = useState({ charges: [], total: 0 });
  const tenant = useSelector((state) => state.auth.profile);

  useEffect(() => {
    const foundDiscount =
      unit?.discounts?.[0] || reservation?.unitDetails?.discounts?.[0];
    if (foundDiscount) {
      setDiscount(foundDiscount);
      setMoveInCosts(foundDiscount.moveInCosts || { charges: [], total: 0 });
    }
  }, [unit, reservation]);
  let actionName = "Rent Now";
  let type = 0;
  if (reservation?.rentalInfo?.contacts?.[0] && leaseSignUrl) {
    type = 4;
    actionName = "Sign Lease";
  } else if (reservation?.rentalInfo?.contacts?.[0]) {
    type = 5;
    actionName = "Move In";
  } else if (
    reservation?.rentalInfo &&
    (!reservation?.rentalInfo?.contacts ||
      reservation.rentalInfo.contacts.length === 0)
  ) {
    type = 2;
    actionName = "Add Contact";
  } else if (reservation) {
    type = 1;
    actionName = "Rent Now";
  }

  const handleRentNowClick = () => {
    dispatch(
      openModal({
        type: type,
        unit,
        facilityId: facility.id,
        reservation,
      })
    );
  };

  const togglePriceBreakdown = () => {
    setPriceBreakdownOpen((prev) => !prev);
  };

  const handleCancelRental = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to cancel this rental?"
    );
    if (confirmCancel) {
      dispatch(
        cancelRental({
          tenantId: tenant?.i,
          facilityId: facility.id,
          reservationId: reservation?.id,
        })
      )
        .unwrap()
        .then(() => {
          alert("Rental canceled successfully.");
        })
        .catch((error) => {
          console.error("Failed to cancel rental:", error);
          alert("Could not cancel rental. Please try again.");
        });
    }
  };
  function getUnitImage(unit) {
    const unitArea = unit.area || 0;
  
    // Check if it's a vehicle/parking unit
    if (unit.category === "Parking") {
      return unitImageMap["10x20 Vehicle Parking"] || unitImageMap.default;
    }
  
    // Find the closest matching image based on the area thresholds
    let bestMatch = unitImageMap.default; // Default to the large unit image
    let smallestDifference = Infinity; // Track the smallest difference
  
    Object.keys(unitImageMap).forEach((key) => {
      const mapping = unitImageMap[key];
  
      // Skip entries without an area value
      if (!mapping.area) return;
  
      const areaDifference = Math.abs(mapping.area - unitArea);
  
      // Update the best match if the difference is smaller and the mapping is valid
      if (areaDifference < smallestDifference && unitArea <= mapping.area) {
        smallestDifference = areaDifference;
        bestMatch = mapping;
      }
    });
  
    return bestMatch;
  }

  return (
    <UnitsContainer>
      <UnitWrapper>
        <UnitTopInfoWrapper>
          <UnitImage
            src={
              getUnitImage(unit)?.src || unitImageMap.default.src // Fallback to default if no match
            }
            alt={
              unit.category === "Parking" ? "Vehicle Parking Unit" : unit.name
            }
          />
          <UnitContainer>
            <UnitName>
              {unit.area < 100 ? "Small" : unit.area < 200 ? "Medium" : "Large"}
            </UnitName>
            <UnitSize>{unitSize}</UnitSize>
          </UnitContainer>
          <UnitContainer>
            <UnitPrice>${street_rate}/mo</UnitPrice>
            <UnitStrike>${web_rate}</UnitStrike>
          </UnitContainer>
        </UnitTopInfoWrapper>

        {discount?.id && (
          <DiscountBadge>
            <BadgeIcon>
              <FontAwesomeIcon icon={faTag} />
            </BadgeIcon>
            {discount.name}
          </DiscountBadge>
        )}

        <UnitDivider />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {unit?.features
            ?.filter((feature) => validFeatures.has(feature.toLowerCase()))
            .map((feature, index, arr) => {
              const { featureIcon, featureTitle } =
                getFeatureIconAndTitle(feature);
              return (
                <React.Fragment key={index}>
                  <IconWrap>
                    {featureIcon}
                    <UnitFeatureTitle>{featureTitle}</UnitFeatureTitle>
                  </IconWrap>
                  {index < arr.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
        </div>

        {isPriceBreakdownOpen && (
          <PriceBreakdownContainer>
            {moveInCosts.charges.map((charge, index) => (
              <BreakdownRow key={index}>
                <span>{charge.name}</span>
                <span>${(charge.amount + charge.tax).toFixed(2)}</span>
              </BreakdownRow>
            ))}
            <hr />
            <BreakdownRow>
              <BreakdownTitle>Total Move-In Cost</BreakdownTitle>
              <BreakdownTotal>${moveInCosts.total?.toFixed(2)}</BreakdownTotal>
            </BreakdownRow>
            <Note>
              *Prices may not include all taxes. Applicable taxes will be added
              to the total amount.
            </Note>
          </PriceBreakdownContainer>
        )}

        {!hideButtons ? (
          <ActionButton onClick={handleRentNowClick}>{actionName}</ActionButton>
        ) : (
          <ToggleButton onClick={togglePriceBreakdown}>
            Price Breakdown {isPriceBreakdownOpen ? "▲" : "▼"}
          </ToggleButton>
        )}

        {reservation &&
          !reservation.rentalInfo &&
          reservation.status !== "Rented" && (
            <div
              style={{
                textDecoration: "underline",
                color: "#5c5d5d",
                marginTop: "10px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleCancelRental}
            >
              Cancel and find new Storage Space
            </div>
          )}
      </UnitWrapper>
    </UnitsContainer>
  );
}

export default Unit;
