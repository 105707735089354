import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Location from "../components/Location";
import OptionText from "../components/OptionItem";
import BottomButton from "../components/BottomButton";
import PgText from "../components/PageText";
import NickHeaderCenter from "../components/NickHeaderCenter";
import { selectFacilityById } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import Spinner from "../components/Spinner";
import HelperIcons from "../components/HelperIcons";

function Rental() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { isAuthenticated, first } = useSelector((state) => state.auth);
  const storeId = useSelector(selectedStore);

  const facility = useSelector((state) => selectFacilityById(state, storeId));
  useEffect(() => {
    document.title = "Rental Page";
  }, []);
  useEffect(() => {
    if (!facility) {
      navigate("/"); // Navigate back to home if facility is not available
    }
  }, [facility, navigate]);

  if (!facility) {
    return <Spinner />; // You can show a loading spinner here
  }

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
      />
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PgText title={`Rent a storage space or complete my rental`} />
        {!isAuthenticated && (
          <OptionText
            id="new-customer-new-rental-option"
            onClick={() => navigate("/newrental")}
            title="I am a new customer looking to rent."
          />
        )}
        <OptionText
          id="complete-rental-option"
          onClick={() => {
            !isAuthenticated
              ? navigate("/login", { state: { page: "myreservations" } })
              : navigate("/myreservations");
          }}
          title="I've started the process and need to complete my rental."
        />
        <OptionText
          id="customer-new-rental-option"
          onClick={() => {
            !isAuthenticated
              ? navigate("/login", { state: { page: "newrental" } })
              : navigate("/newrental");
          }}
          title="I am an existing customer looking to rent another storage space."
        />
        {facility && (
          <Location
            bg={facility?.beautyShot}
            title={facility?.name}
            address={facility?.address}
            city={facility?.city}
            state={facility?.state}
            zip={facility?.zip_code}
            officeHours={facility?.officeHours}
            accessHours={facility?.accessHours}
          />
        )}
      </div>
      <HelperIcons
      data-testid="help-icons"
      facility={facility} />
    </>
  );
}

export default Rental;
