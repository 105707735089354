import styled from "styled-components";

const THWrapBorder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px dashed;
  align-items: center;
  width: 100%;
  max-width: 425px;
`;

const PaymentCC = styled.p`
  color: #000;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
`;

const CCAmount = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const CCdate = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

function Transactions(obj) {
  // const rentalPaymentTransaction = obj.transaction.find((transaction) =>
  //   transaction.name.includes("Website Rental Payment -")
  // );

  // const extractedValue = rentalPaymentTransaction
  //   ? rentalPaymentTransaction.name.match(/-\s\*(\d+)/)?.[0] // Get the match directly
  //   : null;

  
  return (
    <THWrapBorder>
      {obj.icon}
      <PaymentCC>Ending {obj.lastfour}</PaymentCC>
      <CCAmount>${obj.amount}</CCAmount>
      <CCdate>{obj.date}</CCdate>
    </THWrapBorder>
  );
}

export default Transactions;
