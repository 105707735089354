import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faUser,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faCity,
  faMapPin,
  faFlag,
  faMoneyBill,
  faBriefcase,
  faClock,
  faCalendarAlt,
  faCheck,
  faCreditCard,
  faFileContract,
  faMapMarkerAlt as faMoveIn,
  faTimes,
  faClipboard, // Import the close (X) icon
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner";

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

// Close Button
const CloseButton = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 1100; /* Higher than the modal content to always stay on top */

  &:hover {
    color: #e22c2a;
  }
`;

const Header = styled.h2`
  text-align: center;
  color: #333;
`;

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

// Styled component for form section with animation
const AnimatedFormSection = styled.div`
  margin-bottom: 20px;
  animation: ${(props) =>
    props.visible
      ? css`
          ${fadeIn} 0.3s ease-in-out
        `
      : css`
          ${fadeOut} 0.3s ease-in-out
        `};
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -30%;
    transform: translateY(-30%);
    height: 2px;
    width: 100%;
    background-color: #e22c2a;
    z-index: 0;
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  color: #e22c2a;
`;

const Input = styled.input`
  padding: 10px 10px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 95%;
  font-size: 14px;
  min-height: 80px;
`;
const Note = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;

const ConfirmButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;

  &:hover {
    background-color: #c92121;
  }
`;

const ToggleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;
const ToggleSubSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
`;

const ToggleLabel = styled.label`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  max-width: 80%;
`;

const Toggle = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  width: 40px;
  height: 20px;
  appearance: none;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;

  &:checked {
    background-color: #e22c2a;
  }

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  &:checked::before {
    transform: translateX(20px);
  }
`;

const ModalNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #c92121;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ToggleText = styled.span`
  margin-top: 5px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

function ExtendedContactModal({ onRequestClose, onNext }) {
  const [isVehicleStored, setIsVehicleStored] = useState(false);
  const [isLean, setIsLean] = useState(false);
  const [isMilitary, setIsMilitary] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Additional Info Modal";
  }, []);

  const activeModalSession = useSelector(
    (state) => state.modal.activeModalSession
  );
  const rentalsLoading = useSelector((state)=>state.rentals.rentLoading)

  // Function to handle clicks outside the modal
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  const handleSubmit = () => {
    setLoading(true);

    // Simulate an API call with a 2-second delay
    setTimeout(() => {
      setLoading(false);
      onNext(
        activeModalSession
          ? 4
          : reservation?.rentalInfo?.contacts?.[0] && leaseSignUrl
            ? 4
            : 5
      );
    }, 2000);
  };

  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <Header>Additional Details</Header>
        {loading || rentalsLoading ? (
          <Spinner />
        ) : (
          <>

            {/* Steps Container */}
            <StepsContainer>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faCreditCard} />
                </StepCircle>
                <StepLabel>Enter Payment</StepLabel>
              </Step>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faUser} />
                </StepCircle>
                <StepLabel>Alt Contact</StepLabel>
              </Step>
              <Step>
                <StepCircle completed>
                  <FontAwesomeIcon icon={faClipboard} />
                </StepCircle>
                <StepLabel>More Info</StepLabel>
              </Step>
              <Step>
                <StepCircle>
                  <FontAwesomeIcon icon={faFileContract} />
                </StepCircle>
                <StepLabel>Sign Lease</StepLabel>
              </Step>
            </StepsContainer>

            {/* Vehicle Storage Section */}
            <ToggleSection>
              <ToggleLabel>
                Are you storing a vehicle (car, boat, RV, etc.)?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isVehicleStored}
                  onChange={() => setIsVehicleStored(!isVehicleStored)}
                />
                <ToggleText>{isVehicleStored ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isVehicleStored}>
              <Label>Vehicle Details *</Label>
              <Textarea placeholder="Enter details about your vehicle(s) here." />
            </AnimatedFormSection>

            {/* Lien Section */}
            <ToggleSection>
              <ToggleLabel>
                Do you have a lien(s) on an item or items you’re storing?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle checked={isLean} onChange={() => setIsLean(!isLean)} />
                <ToggleText>{isLean ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isLean}>
              <Label>Name of Lien Holder *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Name of Lien Holder" />
                <Icon icon={faUser} />
              </InputWrapper>

              <Label>Value of Lien Items *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Value of Lien Items" />
                <Icon icon={faMoneyBill} />
              </InputWrapper>

              <Label>List of Items With Lien *</Label>
              <Textarea placeholder="List any items with a lien here." />
            </AnimatedFormSection>

            {/* Military Member Section */}
            <ToggleSection>
              <ToggleLabel>
                Are you an active member of the military?
              </ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isMilitary}
                  onChange={() => setIsMilitary(!isMilitary)}
                />
                <ToggleText>{isMilitary ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isMilitary}>
              <Label>Military Branch *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Military Branch" />
                <Icon icon={faFlag} />
              </InputWrapper>

              <Label>Military Base *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Military Base" />
                <Icon icon={faFlag} />
              </InputWrapper>

              <Label>Commanding Officer First Name *</Label>
              <InputWrapper>
                <Input
                  type="text"
                  placeholder="Commanding Officer First Name"
                />
                <Icon icon={faUser} />
              </InputWrapper>

              <Label>Commanding Officer Last Name *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Commanding Officer Last Name" />
                <Icon icon={faUser} />
              </InputWrapper>

              <Label>Commanding Officer's Phone Number *</Label>
              <InputWrapper>
                <Input type="text" placeholder="(___) ___-____" />
                <Icon icon={faPhone} />
              </InputWrapper>
            </AnimatedFormSection>

            {/* Business Rental Section */}
            <ToggleSection>
              <ToggleLabel>Are you renting under a business name?</ToggleLabel>
              <ToggleSubSection>
                <Toggle
                  checked={isBusiness}
                  onChange={() => setIsBusiness(!isBusiness)}
                />
                <ToggleText>{isBusiness ? "Yes" : "No"}</ToggleText>
              </ToggleSubSection>
            </ToggleSection>
            <AnimatedFormSection visible={isBusiness}>
              <Label>Legal Name of Business *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Legal Name of Business" />
                <Icon icon={faBriefcase} />
              </InputWrapper>

              <Label>How long do you plan to store? *</Label>
              <InputWrapper>
                <Input type="text" placeholder="Select Storage Period" />
                <Icon icon={faCalendarAlt} />
              </InputWrapper>
            </AnimatedFormSection>

            <ConfirmButton onClick={handleSubmit}>Submit Details</ConfirmButton>
            <Note>Almost there!</Note>
          </>
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ExtendedContactModal;
