import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Access = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
`;

const CodeTitle = styled.p`
  color: #e22c2a;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  text-align: left;
`;

const AccessCode = styled.p`
  color: #e22c2a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;
const UnitHelp = styled.button`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #e22c2a ;
  background-color: #e22c2a ;
  border-radius: 3px;
  margin-top: 20px ;
  padding: 6px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
`

function Code({ icon, codeTitle, accessCode, onOpenModal, unitHelp, facility }) {
  return (
    <>
      <Wrapper>
        <CodeTitle>{codeTitle}</CodeTitle>
        <Access>
          {icon}
          <AccessCode>{accessCode}</AccessCode>
        </Access>
        {unitHelp && (
          <UnitHelp onClick={facility?.engrain?.h ? onOpenModal : null}>{unitHelp}</UnitHelp>
        )}
      </Wrapper>
    </>
  );
}

export default Code;
