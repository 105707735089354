import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PgText from "../components/PageText";
import AmountOwed from "../components/AmountOwed";
import PaymentHistory from "../components/PaymentHistory";
import Location from "../components/Location";
import HeaderCenter from "../components/HeaderCenter";
import NickHeaderCenter from "../components/NickHeaderCenter";
import BottomButton from "../components/BottomButton";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore, selectedTenantId } from "../redux/authSlice";
import { fetchCharges, fetchTransactions } from "../redux/paymentsSlice"; // Import fetchCharges
import HelperIcons from "../components/HelperIcons";
import Spinner from "../components/Spinner";

function BillPay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeId = useSelector(selectedStore);
  const tenantId = useSelector(selectedTenantId);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const profile = useSelector((state) => state.auth.profile);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { charges, loading } = useSelector((state) => state.payments); // Select charges from the payments slice
  const transactions = useSelector((state) => state.payments.transactions); // Select transactions from the payments slice
  useEffect(() => {
    document.title = "Bill Pay Page";
  }, []);
  useEffect(() => {
    if (storeId && tenantId) {
      // Check for both storeId and tenantId
      if (!facility) {
        dispatch(fetchFacility(storeId, tenantId));
      }
      // Only fetch units once when the component mounts and tenantId is available
      dispatch(fetchCharges({ facilityId: storeId, tenantId }));
      dispatch(fetchTransactions({ facilityId: storeId, tenantId }));
    }
  }, [dispatch, storeId, tenantId, facility]);

  const getAmountOwedDetails = (charges) => {
    if (charges.length === 0)
      return { due: 0, dueDate: "N/A", autoPay: "Auto pay is not active" };

    const totalDue = charges.reduce(
      (sum, charge) => sum + charge.amount + charge.tax,
      0
    );
    const dueDate = charges[0].end_date; // Assuming the due date is the end date of the first charge period.
    const autoPay = "Auto pay is not active"; // You can adjust this based on actual data if available.
    return { due: totalDue.toFixed(2), dueDate, autoPay };
  };

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
      />
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "100px",
        }}
      >
        <PgText
          title={`Make a payment`}
          text="Make a one time payment for your storage space."
        />
        {loading && <Spinner />} {/* Show Spinner after PgText */}
        {!loading && Array.isArray(charges) && charges.length > 0
          ? charges.map((unit) => {
              const matchedUnit = profile?.units?.find(
                (profUnit) => profUnit.id === unit.unit_id
              );
              const userEmail = profile?.email;
              const { due, dueDate, autoPay } = getAmountOwedDetails(
                unit.charges
              );

              if (matchedUnit && matchedUnit.status !== "Moved Out") {
                return (
                  <AmountOwed
                    key={unit.unit_id}
                    unitNumber={unit.unit_id}
                    due={due}
                    dueDate={dueDate}
                    autoPay={unit?.autopay?.status || null}
                    facilityId={storeId}
                    tenantId={tenantId}
                    unitName={matchedUnit.name}
                    rentalId={matchedUnit.rental_id}
                    userEmail={userEmail}
                    facility={facility}
                  />
                );
              }
              return null; // Ensure nothing is rendered if conditions are not met
            })
          : !loading && (
              <p>You're all paid up!</p>
            ) // Show fallback message when there are no charges
        }
        <PaymentHistory transactions={transactions} />
        {facility && (
          <Location
            bg={facility?.beautyShot}
            title={facility?.name}
            address={facility?.address}
            city={facility?.city}
            state={facility?.state}
            zip={facility?.zip_code}
            officeHours={facility?.officeHours}
            accessHours={facility?.accessHours}
          />
        )}
      </div>
      <HelperIcons
      data-testid="help-icons"
      facility={facility} />
    </>
  );
}

export default BillPay;
